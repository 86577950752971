import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { navigate } from "gatsby";
import api from "../../utils/api/api";
import appointmentService from "../../utils/api/v1/appointmentService";
import * as moment from "moment";
import { isEmpty } from "lodash";
import { useQueryParam, StringParam } from "use-query-params";

// components
import PrivateRoute from "../../components/Authentication/PrivateRoute";
import Flex from "../../components/Containers/Flex";
import Bold from "../../components/Typography/Bold";
import LoadingError from "../../components/Loaders/LoadingError";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

// interfaces
import { User } from "../../utils/interfaces/User";
import { FaExternalLinkAlt } from "react-icons/fa";

const Header = styled.div`
  max-width: 1400px;
  padding: 0 3rem 2rem 3rem;
  margin: 0 auto;
`;

const TableWrapper = styled.div`
  margin: 0rem 3rem 3rem;
`;

const InfoTableCell = styled(TableCell)`
  position: relative;

  &.conflicting {
    &:after {
      content: "Conflicto de Horario";
      position: absolute;
      top: 0px;
      right: 0px;
      content: "";
      height: 100%;
      width: 40px;
      background-color: red;
    }
  }
  .MuiSvgIcon-root {
    height: 0.875rem;
    width: 0.875rem;
    margin: 0rem 1rem;
  }

  &:hover {
    background-color: whitesmoke;
    cursor: pointer;
  }
`;

moment.locale("es");

interface PatientRequest {
  data: {
    count: number;
    next: string | null;
    previous: string | null;
    results: User[];
  };
}

const IndexPage = (props): JSX.Element => {
  const [error, setError] = useState<Object>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<Array<User>>([]);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [nextRequest, setNextRequest] = useState<string | null>(null);
  const [prevRequest, setPrevRequest] = useState<string | null>(null);
  const [search, setSearch] = useQueryParam("search", StringParam);

  const fetchUsers = async (): Promise<void> => {
    setLoading(true);
    try {
      const request: PatientRequest = await appointmentService.fetchUsers();
      setUsers(request.data.results);
      setTotalRows(request.data.count);
      setNextRequest(request.data.next.replace("http://", "https://"));
      setPrevRequest(request.data.previous.replace("http://", "https://"));
    } catch (err) {
      console.log(err);
      setError(err);
    }
    setLoading(false);
  };

  const fetchUsersBySearch = async () => {
    setLoading(true);
    try {
      const request = await appointmentService.fetchUsers(search);
      setUsers(request.data.results);
      setTotalRows(request.data.count);
      setNextRequest(request.data.next.replace("http://", "https://"));
      setPrevRequest(request.data.previous.replace("http://", "https://"));
    } catch (err) {
      console.log(err);
      setError(err);
    }
    setLoading(false);
  };

  const fetchNewPage = async (action: "next" | "previous"): Promise<void> => {
    setLoading(true);
    try {
      const request = await api.request({
        method: "get",
        baseURL: "",
        url: action === "next" ? nextRequest : prevRequest,
      });
      setUsers(request.data.results);
      setTotalRows(request.data.count);
      setNextRequest(request.data.next.replace("http://", "https://"));
      setPrevRequest(request.data.previous.replace("http://", "https://"));
    } catch (err) {
      console.log(err);
      setError(err);
    }
    setLoading(false);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    const action = currentPage > newPage ? "previous" : "next";
    fetchNewPage(action);
    setCurrentPage(newPage);
  };

  const displayTableRows = (): Array<JSX.Element> => {
    return users.map((user: User) => {
      return (
        <TableRow key={user.id} hover style={{cursor: 'pointer'}} onClick={() => {
          navigate(`/users/${user.id}/`);
        }}>
          <TableCell>{`${user.first_name} ${user.last_name}`}</TableCell>
          <TableCell>{user.document_number || user["rut"]}</TableCell>
          <TableCell>{user.phone}</TableCell>
          <TableCell>
            {user.gender === "male"
              ? "Masculino"
              : user.gender === "female"
              ? "Femenino"
              : "No especifica"}
          </TableCell>
        </TableRow>
      );
    });
  };

  useEffect((): void => {
    if (!search) {
      fetchUsers();
    }
  }, []);

  useEffect(() => {
    if (search) {
      fetchUsersBySearch();
    }
  }, [search]);

  return (
    <PrivateRoute>
      <LoadingError error={error} loading={loading} />
      {!loading && isEmpty(error) && (
        <>
          <Header>
            <Flex align="center">
              <h1>Lista de Usuarios</h1>
            </Flex>
          </Header>
          <TableWrapper>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell><Bold>Usuario</Bold></TableCell>
                    <TableCell><Bold>RUT/Pasaporte</Bold></TableCell>
                    <TableCell><Bold>Teléfono</Bold></TableCell>
                    <TableCell><Bold>Género</Bold></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{displayTableRows()}</TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      count={totalRows}
                      rowsPerPage={20}
                      rowsPerPageOptions={[20]}
                      page={currentPage}
                      onPageChange={handleChangePage}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </TableWrapper>
        </>
      )}
    </PrivateRoute>
  );
};

export default IndexPage;

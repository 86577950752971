import api from "../api";
import { AxiosResponse } from "axios";
import { User } from "../../interfaces/User";
import { UploadDoctorData } from "../../interfaces/Appointment";
import { Patient } from "../../interfaces/AppointmentPatient";
import {
  ICreateMultipleV2Data,
  ICreateMultipleV2Response,
  IAssistedSetPatientsPayload,
  IAssistedSetPatientsResponse,
} from "../../interfaces/Funnel";

const appointmentsBaseUrl = "appointments";
const paymentsBaseUrl = "payments";
const dashboardAppointmentsBaseUrl = "dashboard/appointments";
const dashboardPatientsBaseUrl = "dashboard/patient";

class AppointmentService {
  fetchAppointment = (id: string): Promise<AxiosResponse<any>> => {
    return api.get(`${appointmentsBaseUrl}/${id}/`);
  };

  fetchDashboardAppointment = (id: string): Promise<AxiosResponse<any>> => {
    return api.get(`${dashboardAppointmentsBaseUrl}/${id}`);
  }

  searchAppointments = (search: string): Promise<AxiosResponse<any>> => {
    return api.get(`${dashboardAppointmentsBaseUrl}/search/`, {
      params: { value: search },
    });
  };

  updateAppointment = (id: string, data: Object) => {
    return api.put(`${appointmentsBaseUrl}/${id}/`, (data = data));
  };

  updateSalesSource = (id: string, data: Object) => {
    return api.patch(`${dashboardAppointmentsBaseUrl}/${id}/`, (data = data));
  };

  reassignAppointment = (
    id: string,
    data: Object
  ): Promise<AxiosResponse<any>> => {
    return api.put(`${appointmentsBaseUrl}/${id}/reassign/`, (data = data));
  };

  rescheduleAppointment = (
    id: string,
    data: Object
  ): Promise<AxiosResponse<any>> => {
    return api.put(`${appointmentsBaseUrl}/${id}/reschedule/`, (data = data));
  };

  fetchUser = (id?: string): Promise<AxiosResponse<any>> => {
    return api.get(`${dashboardPatientsBaseUrl}/${id}/`);
  };

  fetchUsers = (search?: string): Promise<AxiosResponse<any>> => {
    return api.get(`${dashboardPatientsBaseUrl}/`, {
      params: { value: search },
    });
  };

  updateUser = (
    id: string,
    data: Partial<User>
  ): Promise<AxiosResponse<any>> => {
    delete data.country;
    return api.patch(`${dashboardPatientsBaseUrl}/${id}/`, (data = data));
  };

  fetchPatient = (id: string): Promise<AxiosResponse<any>> => {
    return api.get(`${dashboardPatientsBaseUrl}/${id}/get_patient/`);
  };

  fetchPatients = (search?: string): Promise<AxiosResponse<any>> => {
    return api.get(`${dashboardPatientsBaseUrl}/patients_list/`, {
      params: { value: search },
    });
  };

  updatePatient = (
    id: string,
    data: Partial<User>
  ): Promise<AxiosResponse<any>> => {
    delete data.country;
    return api.patch(
      `${dashboardPatientsBaseUrl}/${id}/update_patient/`,
      (data = data)
    );
  };

  fetchPossibleNurses = (id: string) => {
    return api.get(`${appointmentsBaseUrl}/${id}/possible_nurses/`);
  };


  createOffliner = (data: Object): Promise<AxiosResponse<any>> => {
    return api.post(`${appointmentsBaseUrl}/offliner/create_offliner/`, (data = data));
  };

  manualConfirm = (
    id: string,
    data: Object = {}
  ): Promise<AxiosResponse<any>> => {
    return api.post(`${appointmentsBaseUrl}/${id}/manual_confirm/`, data);
  };

  cancel = (id: string): Promise<AxiosResponse<any>> => {
    return api.patch(`${appointmentsBaseUrl}/${id}/cancel/`);
  };

  manualCancel = (id: string): Promise<AxiosResponse<any>> => {
    return api.patch(`${appointmentsBaseUrl}/${id}/manual_cancel/`);
  };

  getComments = (id: string): Promise<AxiosResponse<any>> => {
    return api.get(`${appointmentsBaseUrl}/${id}/retrieve_comments/`);
  };

  createComment = (id: string, data: Object): Promise<AxiosResponse<any>> => {
    return api.post(`${appointmentsBaseUrl}/${id}/add_comment/`, (data = data));
  };

  deleteComment = (id: string): Promise<AxiosResponse<any>> => {
    return api.delete(`${appointmentsBaseUrl}/delete_comment/`, {
      params: { comment_id: id },
    });
  };

  getPatients = (id: string): Promise<AxiosResponse<any>> => {
    return api.get(`${dashboardAppointmentsBaseUrl}/${id}/patients/`);
  };

  searchPatient = (
    document_type: string,
    document_number,
    strict: boolean = false
  ): Promise<AxiosResponse<any>> => {
    return api.get(
      `/patients/search?document_type=${document_type}&document_number=${document_number}&strict=${strict}`
    );
  };

  addPatient = (
    appointment_id: string,
    patient_id: string
  ): Promise<AxiosResponse<any>> => {
    return api.post(
      `${dashboardAppointmentsBaseUrl}/${appointment_id}/add_patient/`,
      { patient_id: patient_id }
    );
  };

  createPatient = (
    appointment_id: string,
    data: Patient
  ): Promise<AxiosResponse<any>> => {
    return api.post(
      `${dashboardAppointmentsBaseUrl}/${appointment_id}/create_patient/`,
      data
    );
  };

  changeAddress = (id: string, data: Object): Promise<AxiosResponse<any>> => {
    return api.patch(
      `${appointmentsBaseUrl}/${id}/change_address/`,
      (data = data)
    );
  };
  getFlatfileToken = (): Promise<AxiosResponse<any>> => {
    return api.get(`${appointmentsBaseUrl}/flatfile_token_mx/`);
  };

  getFlatfileTokenMx = (): Promise<AxiosResponse<any>> => {
    return api.get(`${appointmentsBaseUrl}/flatfile_token_mx/`);
  };

  downloadFlatfileTemplate = (): Promise<AxiosResponse<any>> => {
    return api.get(`${appointmentsBaseUrl}/flatfile_template/`, {
      responseType: "blob",
    });
  };

  uploadRegistryImage = (appointmentId: string, formData: FormData) => {
    return api.post(
      `${appointmentsBaseUrl}/${appointmentId}/add_document/`,
      formData
    );
  };

  createMultipleV2 = (
    data: ICreateMultipleV2Data
  ): Promise<AxiosResponse<ICreateMultipleV2Response>> => {
    return api.post<ICreateMultipleV2Response>(
      `${appointmentsBaseUrl}/create_multiple_v2/`,
      data
    );
  };

  assistedSetPatients = (
    id: string,
    data: IAssistedSetPatientsPayload
  ): Promise<AxiosResponse<IAssistedSetPatientsResponse>> => {
    return api.post<IAssistedSetPatientsResponse>(
      `${appointmentsBaseUrl}/${id}/assisted_set_patients/`,
      data
    );
  };

  setPatientsAndCart = (
    id: string,
    data: IAssistedSetPatientsPayload
  ): Promise<AxiosResponse<IAssistedSetPatientsResponse>> => {
    return api.post<IAssistedSetPatientsResponse>(
      `v2/appointments/${id}/assisted_set_patients/`,
      data
    );
  };

  fetchFingerprintAppointments = (
    beginDate: string,
    endDate: string,
    status: string,
    insurance: string
  ): Promise<AxiosResponse<any>> => {
    return api.get(`${appointmentsBaseUrl}/fingerprint-required/`, {
      params: { begin_date: beginDate, end_date: endDate, status, insurance },
    });
  };

  totalpackValuation = (id: string): Promise<AxiosResponse<any>> => {
    return api.post(`${appointmentsBaseUrl}/${id}/totalpack_valuation/`, {
      from_betty: true,
    });
  };

  totalpackPaymentConfirm = (
    id: string,
    prebonoIds: any
  ): Promise<AxiosResponse<any>> => {
    return api.post(`${appointmentsBaseUrl}/${id}/totalpack_confirm_payment/`, {
      prebono_patient_ids: prebonoIds,
    });
  };

  uploadMedicalOrderImageToCreatedAppointment = (
    appointmentId: string,
    formData: FormData,
  ) => {
    formData.append("triggered_from", "betty");
    return api.post(
      `${appointmentsBaseUrl}/${appointmentId}/upload_image_medical_order/`,
      formData,
    );
  };

  getDoctorData = (appointmentId: string) => {
    return api.get(`${appointmentsBaseUrl}/${appointmentId}/get_doctor_data/`);
  }

  uploadMedicalOrderDoctorData = (
    appointmentId: string,
    data: UploadDoctorData,
  ) => {
    return api.post(
      `${appointmentsBaseUrl}/${appointmentId}/upload_doctor_data/`,
      data
    );
  }

  sendToTotalpackDashboard = (id: string): Promise<AxiosResponse<any>> => {
    return api.post(`${appointmentsBaseUrl}/${id}/totalpack-dashboard/`);
  };

  removeFromTotalpackDashboard = (id: string): Promise<AxiosResponse<any>> => {
    return api.delete(`${appointmentsBaseUrl}/${id}/totalpack-dashboard/`);
  };

  registerPayment = (
    appointmentId: string,
    data: Object
  ): Promise<AxiosResponse<any>> => {
    return api.post(
      `${paymentsBaseUrl}/appointments/${appointmentId}/register_payment/`,
      data
    );
  };

  deletePayment = (
    appointmentId: string,
    paymentId: string
  ): Promise<AxiosResponse<any>> => {
    return api.delete(
      `${paymentsBaseUrl}/appointments/${appointmentId}/delete_payment/`,
      {
        params: { id: paymentId },
      }
    );
  };

  getOperativeData = (id: string): Promise<AxiosResponse<any>> => {
    return api.get(`${appointmentsBaseUrl}/${id}/operative_data/`);
  };

  updateOperativeData = (
    id: string,
    data: Object
  ): Promise<AxiosResponse<any>> => {
    return api.put(`${appointmentsBaseUrl}/${id}/operative_data/`, data);
  };

  fetchSalesSources = (): Promise<AxiosResponse<any>> => {
    return api.get(`${appointmentsBaseUrl}/sales_sources`);
  };

  releaseBlock = (id: string): Promise<AxiosResponse<any>> => {
    return api.post(`${dashboardAppointmentsBaseUrl}/${id}/release_block`);
  }

  manualFiscoclicInvoice = (id: string): Promise<AxiosResponse<any>> => {
    return api.post(`/plugins/fiscoclic/manual_invoice/`, { appointment_id: id });
  }
}

const appointmentService = new AppointmentService();

export default appointmentService;
